<template>
  <div>
    <h1>Dragonfly</h1>
  </div>
  
</template>

<script>
export default {
  name: 'Landing',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-family: 'Pacifico', cursive;
    font-size: 48px;
    font-weight: 100;
    margin-top: 200px;
    opacity: .75;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
